import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { IArticle, IBrand, IMenu, IMeta, ITextBook, ITextbookBasicData, S3Server } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";
import ContentsList from "./TextBookIndexList";
import TextBookEdit from "./TextBookEdit";
import Tooltip from "@mui/material/Tooltip";
import PopupHistoryNDeploy from "./PopupHistoryNDeploy";
import PopupDeployHistory from "./PopupDeployHistory";
import TextBookEdit_1 from "./TextBookEdit_1";
import PopupConfirm from "../contents/PopupConfirm";
import TextBookEvaluation from "./TextBookEvaluation";
import PopupUpdateHistory from "./PopupUpdateHistory";
import TextBookHistoryList from "./TextBookHistoryList";

interface ITextBookList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;
    showContentsList: (view: boolean) => void;
}
  
function TextBookList(props: ITextBookList) {
    
    const [list, setList] = useState([] as any);
    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [isEditing, setIsEditing] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null as any);
    const [viewHistoryDeploy, setViewHistoryDeploy] = useState(false);
    const [viewDeployHistory, setViewDeployHistory] = useState(false);
    const [selectedEditItem, setSelectedEditItem] = useState(null as any);

    const [viewConfirmDelete, setViewConfirmDelete] = useState(false);    
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteDesc, setDeleteDesc] = useState("");

    const [viewEvaluation, setViewEvaluation] = useState(false);
    // const [viewUpdateHistory, setViewUpdateHistory] = useState(false);

    const [viewHistoryList, setViewHistoryList] = useState(false);

    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            const _pageNum = localStorage.getItem("TextBook_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            const div = document.getElementsByClassName("SG-datepicker__input-container");
            if(div) {
                
                const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
                if(!new_TB_sdate) {
                    let node = document.createElement("input");
                    node.id = "new_TB_sdate";
                    node.type = "text";
                    node.placeholder = "시작일";
                    node.value = ""
                    node.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node.addEventListener("keydown", (e) => {
                        e.stopPropagation();                                  
                    })
        
                    div[0].appendChild(node)
    
                }

                const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                if(TB_sdate) {
                    TB_sdate.style.display = "none";
                }

                const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
                if(!new_TB_edate) {
                    let node_e = document.createElement("input");
                    node_e.id = "new_TB_edate";
                    node_e.type = "text";
                    node_e.placeholder = "종료일";
                    node_e.value = ""
                    node_e.addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                    node_e.addEventListener("keydown", (e) => {
                        e.stopPropagation();
                    })
        
                    div[1].appendChild(node_e)
                }

                const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                if(TB_edate) {
                    TB_edate.style.display = "none";
                }
            }

        }
        bDone = true;
    }, [])

    let preBrand: common.IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            if(isEditing) {
                setIsEditing(false);
            }
        }
    }, [props.menu])

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);

        // const brand = util.getCurrentBrand();
        // if(!brand) {
        //     return;
        // }
        // setCurrPage(1);

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            data.detail = true;
        }
        
        setViewList(false);

        const list = await util.getTextbookSearch(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count ? list[0].full_count : 0;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const TB_keyword = document.getElementById("TB_keyword") as HTMLInputElement;
        const TB_keyword_type = document.getElementById("TB_keyword_type") as HTMLInputElement;
        
        const TB_curriBook = document.getElementById("TB_curriBook") as HTMLSelectElement;
        const TB_curriBook_val = curriInfo.curriBook[TB_curriBook.selectedIndex-1];
        const TB_usage = document.getElementById("TB_usage") as HTMLSelectElement;
        const TB_usage_val = curriInfo.textbookCategory[TB_usage.selectedIndex-1];

        const TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        const TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
        
        let sdate = undefined;
        let edate = undefined;

        if(TB_sdate && TB_edate) {
            const arrSD = TB_sdate.value.split("-");
            const arrED = TB_edate.value.split("-");
            if(arrSD.length === 3 && arrED.length === 3) {
                sdate = TB_sdate.value;
                edate = TB_edate.value 
            }      
        }

        let search = "";
        if(TB_keyword) {
            search = TB_keyword.value;
        }
        let searchField = "";
        if(TB_keyword_type) {
            searchField = TB_keyword_type.value;
        }

        const data = {
            brand_id: brand.id ? brand.id : null,

            search: search,
            searchField: searchField,

            // keyword: TB_keyword_type && TB_keyword_type.value === "keyword" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,
            // creator: TB_keyword_type && TB_keyword_type.value === "id" ? (TB_keyword && TB_keyword.value !== "" ? TB_keyword.value : undefined) : undefined,

            type: "web",
            curriBook: TB_curriBook_val ? TB_curriBook_val.id : undefined,
            textbookCategory: TB_usage_val ? TB_usage_val.id : undefined,

            reg_sdate: sdate,  
            reg_edate: edate,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    onChangePage(_currPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("TextBook_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    function onDeployHistory() {
        setViewDeployHistory(true);
    }

    function onManageIndex() {
        props.showContentsList(true);
    }

    function onEditTextBook(textbook: ITextBook | null = null) {
        setSelectedEditItem(textbook);
        setIsEditing(true);
    }

    function onUpdateHistory(textbook: ITextBook) {
        // alert("작업중입니다.");
        setSelectedItem(textbook);
        setIsEditing(true);
        setViewHistoryList(true);
    }

    async function onCopy() {

        props.showLoading(true);

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_radio_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {
                logger.log("onCopy", item);

                const data = {
                    id: item.id,
                }
                const result = await util.copyTextbook(item.id!);
                if(!result) {
                    await util.onCheckLoginApi();
                    props.showModal("", "오류가 있습니다. 관리자에게 문의해 주시기 바랍니다.");
                } else {
                    logger.log("onCopy", result);
                }
                
                _onSearch(searchData);
    
                break;
            }            
        }

        props.showLoading(false);

    }

    async function onDelete() {

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_radio_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {

                logger.log("onDelete", item);

                const isAdmin = util.getHasAuthMenu("");
                if(isAdmin) {
                } else {
                    if(item.creator !== util.getUserInfo("uid")) {
                        props.showModal("", "삭제 권한이 없습니다.");
                        return;    
                    }
                }
    
                if(item.deployStatus > 0) {
                    props.showModal("", "배포된 교과서는 삭제가 불가능합니다.");
                    return;
                }

                setViewConfirmDelete(true);
                setDeleteTitle("삭제");
                setDeleteDesc("삭제하시겠습니까? 복구가 불가합니다.");
           
                return;
            }            
        }
    }

    async function onDeleteOK() {

        onCloseDelete();

        for (let i = 0; i < list.length; i++) {
            const item = list[i] as ITextBook;
            const radio = document.getElementById("TB_radio_"+i) as HTMLInputElement;
            if(item && radio && radio.checked) {
                props.showLoading(true);        

                const result = await util.deleteTextbook(item.id!);
                logger.log("onDeleteOK", result);
                
                _onSearch(searchData);
        
                props.showLoading(false);       
                
                return;
            }
        }
    }
    
    function onCloseDelete() {
        setViewConfirmDelete(false);
    }

    function onDeploy(textbook: ITextBook) {
        logger.log("onDeploy", textbook);
        setSelectedItem(textbook);
        setViewHistoryDeploy(true)
    }

    function onClickSDateIcon() : void {
        const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
        if(new_TB_sdate) {
            // new_TB_sdate.value = "";
            const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
            TB_sdate.value = "";
            TB_sdate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickSDate", div, div.length);
                    const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
                    TB_edate.click();
                }
            }, 10);    
        }
    }
        
    function onClickEDateIcon() : void {
        const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
        if(new_TB_edate) {
            // new_TB_edate.value = "";
            const TB_edate = document.getElementById("TB_edate") as HTMLInputElement;
            TB_edate.value = "";
            TB_edate.click();

            setTimeout(() => {
                const div = document.getElementsByClassName("SG-datepicker__calendar-container");
                if(div && div.length === 2) {
                    logger.log("onClickEDate", div, div.length);
                    const TB_sdate = document.getElementById("TB_sdate") as HTMLInputElement;
                    TB_sdate.click();
                }
            }, 10);    
        }
    }

    function onChangeCalendar(e: { target: HTMLInputElement; }): void | undefined {
        logger.log("onChangeCalendar", e.target.id)

        if(e.target.id === "TB_sdate") {
            const new_TB_sdate = document.getElementById("new_TB_sdate") as HTMLInputElement;
            if(new_TB_sdate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_sdate.value = val;
            }
        } else if(e.target.id === "TB_edate") {
            const new_TB_edate = document.getElementById("new_TB_edate") as HTMLInputElement;
            if(new_TB_edate) {
                const arrSD = e.target.value.split("/");
                const val = arrSD[2]+"-"+arrSD[0]+"-"+arrSD[1]                        
                new_TB_edate.value = val;
            }
        }
    }

    function onEvaluation(textbook: ITextBook) {

        // alert("작업중입니다.");
        // return;

        logger.log("onEvaluation", textbook);
        setIsEditing(true);
        setViewEvaluation(true);
        setSelectedItem(textbook);
    }

    return (
        <div>

            {!isEditing && <>
            <div className="top-title">
                <h3>웹교과서 관리</h3>
            </div>

            <div className="" id="subject" role="tabpanel" aria-labelledby="subject-tab"> 

                <div className="mb-3 text-right">
                    <button type="button" className="btn-sky mr-2" onClick={onDeployHistory}>배포이력</button>
                    <button type="button" className="btn-sky mr-2" onClick={onManageIndex}>목차관리</button>
                    <button type="button" className="btn-sky" onClick={() => onEditTextBook()}>교과서 등록</button>
                </div>

                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div>
                        {/* <fieldset>
                            <label htmlFor="search-3">교육과정</label>
                            <select id="TB_curriYear" name="search-3">
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriYear && (curriInfo?.curriYear as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-8">학교급</label>
                            <select id="TB_curriSchool" name="search-4">
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo?.curriSchool && (curriInfo?.curriSchool as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>
                                );
                            })}
                            </select>
                        </fieldset> */}
                        <fieldset>
                            <label htmlFor="curriBook" className="ml-8">교과과정</label>
                            <select id="TB_curriBook" name="TB_curriBook">
                                <option value="">전체</option>              
                                {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">교과서 유형</label>
                            <select id="TB_usage" name="search-5">
                                <option value={""}>전체</option>
                                {curriInfo && curriInfo.textbookCategory && (curriInfo.textbookCategory as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.val}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                        <fieldset>
                            <label htmlFor="date" className="ml-8">등록일​</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_sdate' name='TB_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px] ml-5" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='TB_edate' name='TB_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                    </div>

                    <div className="mt-3 text-right">

                        <fieldset>
                            <label htmlFor="keyword_type">검색</label>

                            <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                {/* <option value={"keyword"}>검색어</option> */}
                                <option value={"name"}>제목</option>
                                <option value={"creator"}>등록자</option>
                                <option value={"id"}>Key</option>
                                {/* <option value={"hashTags"}>해시태그</option> */}
                            </select>

                            {/* <select className="mr-2" id="TB_keyword_type" name="keyword_type">
                                <option value={"keyword"}>검색어</option>
                                <option value={"id"}>등록자</option>
                            </select> */}

                            <input type="text" name="keyword" id="TB_keyword" className="w-[300px] mr-2" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>

                        {/* <fieldset>
                            <label htmlFor="search-6">검색</label>
                            <input type="text" name="search" id="TB_keyword" placeholder="키워드 검색" className="w-[300px] ml-3" />
                            <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset> */}
                    </div>                        
                </div>
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3">
                    <form action="" className="flex justify-between">
                        <fieldset>
                            <span>총 <strong className="text-red">{listCount}</strong>건</span>
                            <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                            <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={100}>100</option>
                            </select>
                        </fieldset>
                    </form>                    
                </div>

                <div className="mt-3 mb-5 text-right">
                    <button type="button" className="btn-white mr-2" onClick={onCopy}>복사</button>
                    <button type="button" className="btn-white" onClick={onDelete}>삭제</button>
                </div>

				{/* <!-- table --> */}
                {viewList &&
				<table>
					<caption className="sr-only">웹교과서 목록</caption>
					<thead>
						<tr>
							<th scope="col" className="pl-4 pr-3">
                                {/* <input type="checkbox" className="h-4 w-4 rounded" onClick={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    if(input) {
                                        (list as ITextBook[]).map((_, idx) => {
                                            const TB_check = document.getElementById("TB_check_"+idx) as HTMLInputElement;
                                            TB_check.checked = input.checked;
                                        })
                                    }
                                }}/> */}
                                <input type="radio" className="h-4 w-4 rounded" disabled/>
                            </th>
							<th scope="col" className="px-3">번호</th>
							<th scope="col" className="px-3">Key</th>
							<th scope="col" className="px-3">교과과정</th>
							{/* <th scope="col" className="px-3">학교급</th> */}
							{/* <th scope="col" className="px-3">교과</th> */}
							<th scope="col" className="px-3">교과서 제목</th>
							{/* <th scope="col" className="px-3">하위자료</th> */}
							<th scope="col" className="px-3">교과서 유형</th>
							{/* <th scope="col" className="px-3">사용 설정</th> */}
							<th scope="col" className="px-3">등록자</th>
							<th scope="col" className="px-3">등록일</th>
                            <th scope="col" className="px-3">과제/평가</th>
							<th scope="col" className="pl-3">배포관리</th>
							<th scope="col" className="pl-3 pr-4">이력</th>
						</tr>
					</thead>
					<tbody>
                        {viewList && list && (list as ITextBook[]).length === 0 &&
                        <tr>    
                            <td colSpan={11}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                        {viewList && list && (list as ITextBook[]).map((item, idx) => {

                        const totalCnt = item.full_count ? item.full_count : 0;
                        const _numOfPage = localStorage.getItem("TextBook_numOfPage") ? Number(localStorage.getItem("TextBook_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        const curriYear = curriInfo?.curriYear.find((year) => year.id === item.curriYear);
                        const curriBook = curriInfo?.curriBook.find((book) => book.id === item.curriBook);
                        const textbookCategory = curriInfo?.textbookCategory.find((category) => category.id === item.textbookCategory);

                        // 0(초기값), 1(배포대기), 2(배포종료), 3(배포보류) , 10(배포완료), 20(배포실패)
                        let deployStatus = "확인";
                        let deployStatusCN = "btn-white-s";
                        if(item.deployStatus === 0) {
                            deployStatus = "확인";   
                        } else if(item.deployStatus === 1) {
                            deployStatus = "배포대기";   
                            deployStatusCN = "btn-sky-s";
                        } else if(item.deployStatus === 2) {
                            deployStatus = "배포종료";   
                        } else if(item.deployStatus === 3) {
                            deployStatus = "배포보류";   
                        } else if(item.deployStatus === 10) {
                            deployStatus = "배포완료";   
                        } else if(item.deployStatus === 20) {
                            deployStatus = "배포실패";   
                        }

                        const hasAuth = util.getHasAuthMenu(item.creator);

                        return (
                            <tr className="rt-tr action" key={idx}>
                                {/* <td><input id={"TB_check_"+idx} type="checkbox" className="h-4 w-4 rounded" /></td> */}
                                <td className="rt-td"><input id={"TB_radio_"+idx} type="radio" name="TB_radio" className="h-4 w-4 rounded" /></td>
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{_num}</td>
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{item.id}</td>
                                {/* <td>{curriYear?.val}</td> */}
                                {/* <td>중등</td> */}
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{curriBook?.val}</td>
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>
                                    <Tooltip key={idx} title="수정" arrow><a href="#" className="underline">{item.name}</a></Tooltip>
                                </td>
                                {/* <td>2</td> */}
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{textbookCategory?.val}</td>
                                {/* <td>Y</td> */}
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{item.creator}</td>
                                <td className="rt-td" onClick={() => onEditTextBook(item)}>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td className="rt-td">
                                    <button type="button" className="btn-white-s" onClick={() => onEvaluation(item)} disabled={!hasAuth}>{item.textbookEvaluationCount > 0 ? "수정" : "설정"}</button>                                    
                                </td>
                                
                                {item.is_publish ? <td>-</td> :
                                <td className="rt-td">
                                    <button type="button" className={deployStatusCN} onClick={() => onDeploy(item)}>{deployStatus}</button>                                    
                                </td>}

                                <td className="rt-td">
                                    <button type="button" className={"btn-white-s"} onClick={() => onUpdateHistory(item)} disabled={!hasAuth}>확인</button>                                    
                                </td>
                            </tr>
                        )})}
					</tbody>
				</table>}
				{/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div></>}

            {isEditing && !selectedEditItem && !viewEvaluation && !viewHistoryList &&
            <TextBookEdit brand={props.brand} menu={props.menu} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                refreshList();
                setIsEditing(false);
            }} />}

            {isEditing && selectedEditItem && !viewEvaluation && !viewHistoryList &&
            <TextBookEdit_1 brand={props.brand} menu={props.menu} originTextBook={selectedEditItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                const _searchData = searchData;
                _onSearch(_searchData);                    
                setIsEditing(false);
            }} />}

            {isEditing && viewEvaluation && !viewHistoryList &&
            <TextBookEvaluation brand={props.brand} menu={props.menu} showModal={props.showModal} selectedItem={selectedItem} showLoading={props.showLoading} onClose={() => {
                const _searchData = searchData;
                _onSearch(_searchData);                    
                setIsEditing(false);
                setViewEvaluation(false);
            }} />}

            {isEditing && viewHistoryList && !viewEvaluation &&
            <TextBookHistoryList brand={props.brand} menu={props.menu} showModal={props.showModal} selectedItem={selectedItem} showLoading={props.showLoading} onClose={() => {
                setIsEditing(false);
                setViewHistoryList(false);
            }} />}

            {viewHistoryDeploy && <PopupHistoryNDeploy textbook={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewHistoryDeploy(false);
            }} onDeploy={() => {
                const _searchData = searchData;
                _onSearch(_searchData);                    
            }} />}

            {viewDeployHistory && <PopupDeployHistory selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewDeployHistory(false);
            }} />}

            <PopupConfirm view={viewConfirmDelete} title={deleteTitle} description={deleteDesc} onClose={onCloseDelete} onOk={onDeleteOK} showModal={props.showModal} />

            {/* {viewUpdateHistory && <PopupUpdateHistory selectedItem={selectedItem} showModal={props.showModal} showLoading={props.showLoading} onClose={() => {
                setViewUpdateHistory(false);
            }} />} */}

        </div>

    );  
}
export default TextBookList;
