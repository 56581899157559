import { ChangeEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import * as common from '../common';
import * as util from '../util';
import { EDITOR_HOST, IArticle, IBrand, IMenu, IMeta, isDev } from "../common";
import { logger } from '../logger';
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import MyModal from "../MyModal";

interface IAITutorContentsList {
    brand: IBrand;
    menu: IMenu;
    showModal: (title: string, description: string) => void;    
    showLoading: (loading: boolean) => void;    
}

interface IArticlePartGroupPart {
    directive: string;
    example: string;
    explanation: string;
    hint: string;
    input_area: string;
    model_answer: string;
    passage: string;
}

export interface IArticlePartGroup {
    id: number;
    part: IArticlePartGroupPart;
}

function AITutorContentsList(props: IAITutorContentsList) {
    
    const [list, setList] = useState([] as any);

    const [listCount, setListCount] = useState(0);

    const [viewList, setViewList] = useState(false);

    const [currPage, setCurrPage] = useState(1);
    const [listOfPage, setListOfPage] = useState([] as any);
    const [searchData, setSearchData] = useState(null as any);

    const [curriUnit1, setCurriUnit1] = useState([] as any);
    const [curriUnit2, setCurriUnit2] = useState([] as any);
    const [curriUnit3, setCurriUnit3] = useState([] as any);
    const [curriUnit4, setCurriUnit4] = useState([] as any);
    const [curriUnit5, setCurriUnit5] = useState([] as any);

    const [curriDepth, setCurriDepth] = useState(0);
    const [bookDescendantsList, setBookDescendantsList] = useState([] as any);
    // const [currArticleCategory, setCurrArticleCategory] = useState("");

    const [evaluationArea1, setEvaluationArea1] = useState<IMeta[]>(null as any);

    const [modalShow, setModalShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null as any);
    
    const currMenu = util.getCurrentMenu();

    const numOfViewPage = 10;

    const curriInfo = util.getCurrentCurriInfo();

    const CL_html_curriUnit1 = document.getElementById("CL_curriUnit1") as HTMLSelectElement;
    const CL_html_curriUnit2 = document.getElementById("CL_curriUnit2") as HTMLSelectElement;
    const CL_html_curriUnit3 = document.getElementById("CL_curriUnit3") as HTMLSelectElement;
    const CL_html_curriUnit4 = document.getElementById("CL_curriUnit4") as HTMLSelectElement;
    const CL_html_curriUnit5 = document.getElementById("CL_curriUnit5") as HTMLSelectElement;

    let bDone = false;
    useEffect(() => {
        if(!bDone) {

            setList([]);
            // setViewList(true);
            // setCurrArticleCategory("교과");

            const _pageNum = localStorage.getItem("AITutor_numOfPage");
            const pagenum = document.getElementById("pagenum") as HTMLSelectElement;
            if(pagenum) {
                if(_pageNum) {
                    pagenum.value = _pageNum;
                } else {
                    pagenum.value = "10";                
                }    
            }

            if(props.brand && props.brand.code === "VAET01") {
                getEvaluationArea();
            }
        }
        bDone = true;
    }, [])

    let preBrand: IBrand | null = null;
    useEffect(() => {
        if(props.brand && props.brand !== preBrand) {
            logger.log("props.brand", props.brand);
            refreshList();
        }
        preBrand = props.brand;
    }, [props.brand])

    useEffect(() => {
        if(props.menu) {
            logger.log("props.menu", props.menu);
            refreshList();
        }
    }, [props.menu])

    const refreshList = () => {

        setSearchData(null);
        setList([]);
        setViewList(true);
        setListCount(0);
        setListOfPage([]);

        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }
        setCurrPage(1);

        if(brand.code === "VAMT01") {
            const CL_contentsType = document.getElementById("CL_contentsType") as HTMLSelectElement;
            CL_contentsType.value = "문항";
            CL_contentsType.disabled = true;
        } else {
            const CL_contentsType = document.getElementById("CL_contentsType") as HTMLSelectElement;
            CL_contentsType.selectedIndex = 0;
            CL_contentsType.disabled = false;
        }

        // let articleCategoryId = undefined;
        // if(curriInfo && curriInfo.articleCategory) {
        //     const articleCategory = curriInfo.articleCategory as IMeta[];
        //     const _articleCategory = articleCategory.find((article, _) => article.val === "교과");
        //     if(_articleCategory) {
        //         articleCategoryId = _articleCategory.id;
        //     }
        // }

        // const data = {
        //     brand_id: brand.id ? brand.id : null,
        //     articleCategory: articleCategoryId,
        //     orderby_col: "id",
        //     orderby_dir: "desc",
        //     limit_page: 1,
        //     limit_pageItemCnt: localStorage.getItem("AITutor_numOfPage") ? Number(localStorage.getItem("AITutor_numOfPage")) : 10,
        // };
        // setSearchData(data);
        // _onSearch(data);

        // logger.log("refreshList", data);
    }

    async function getEvaluationArea() {
        const list1 = await getMetaList("1", "evaluationArea");
        setEvaluationArea1(list1);
    }

    async function getMetaList(depth: string, name: string) {
        const list = await util.getMetaList(depth, name);
        const _list = list.sort((a, b) => a.id! < b.id! ? 1 : -1);	
        const __list = _list.filter((item) => item.is_active === true);

        return __list;
    }

    const calcPage = (_totalCnt: number, _currPage: number) => {

        const _numOfPage = localStorage.getItem("AITutor_numOfPage") ? Number(localStorage.getItem("AITutor_numOfPage")) : 10;

        let _totalPageCnt = (_totalCnt / _numOfPage) | 0;
        const _rest = _totalCnt % _numOfPage;
        if(_rest > 0) {
            _totalPageCnt = _totalPageCnt + 1;
        }

        const _startPage = ((((_currPage-1) / numOfViewPage) | 0) * numOfViewPage) + 1;
        const _endPage = Math.min(_totalPageCnt, _startPage+(numOfViewPage - 1));

        let _list = [];
        for (let i = _startPage; i < _endPage+1; i++) {
            _list.push(i);
        }
        setListOfPage(_list as number[]);

        logger.log("listOfPage", _list, _currPage, _numOfPage, _totalPageCnt, _startPage, _endPage);
    }

    async function _onSearch(data: any) {

        props.showLoading(true);

        if(data) {
            data.detail = true;
            // data.articleCategory = 61;
            // data.limit_pageItemCnt = 1000;
        }

        setViewList(false);

        const list = await util.getAiTutorArticleList(data);
        setList(list);
        logger.log("onSearch", data, list);
        setViewList(true);
        setListCount(0);
        if(list) {
            const currentPage = data.limit_page;
            let totalCnt = 0;
            if(list.length > 0) {
                totalCnt = list[0].full_count;
                setListCount(totalCnt);
            }
            calcPage(totalCnt, currentPage);
        }

        props.showLoading(false);
    }

    function onSearch() {

        if(!curriInfo) {
            return;
        }
    
        const brand = util.getCurrentBrand();
        if(!brand) {
            return;
        }

        const CL_curriBook = document.getElementById("CL_curriBook") as HTMLSelectElement;
        const CL_curriBook_val = curriInfo.curriBook[CL_curriBook.selectedIndex-1];

        const CL_html_curriUnit1_val = curriUnit1 ? (curriUnit1[CL_html_curriUnit1.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit2_val = curriUnit2 ? (curriUnit2[CL_html_curriUnit2.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit3_val = curriUnit3 ? (curriUnit3[CL_html_curriUnit3.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit4_val = curriUnit4 ? (curriUnit4[CL_html_curriUnit4.selectedIndex-1] as IMeta) : undefined;
        const CL_html_curriUnit5_val = curriUnit5 ? (curriUnit5[CL_html_curriUnit5.selectedIndex-1] as IMeta) : undefined;

        const CL_contentsType = document.getElementById("CL_contentsType") as HTMLSelectElement;

        const CL_hint = document.getElementById("CL_hint") as HTMLSelectElement;
        const CL_answer = document.getElementById("CL_answer") as HTMLSelectElement;
        const CL_question = document.getElementById("CL_question") as HTMLSelectElement;

        const CL_learning = document.getElementById("CL_learning") as HTMLSelectElement;
        const CL_conversion = document.getElementById("CL_conversion") as HTMLSelectElement;

        const CL_review = document.getElementById("CL_review") as HTMLSelectElement;

        let articleTypeId = undefined;
        if(CL_contentsType.value !== "") {
            if(curriInfo && curriInfo.articleType) {
                const articleType = curriInfo.articleType as IMeta[];
                const _articleType = articleType.find((article, _) => article.val === CL_contentsType.value);
                if(_articleType) {
                    articleTypeId = _articleType.id;
                }
            }    
        }

        const CL_keyword = document.getElementById("CL_keyword") as HTMLInputElement;        
        const CL_keyword_type = document.getElementById("CL_keyword_type") as HTMLInputElement;

        let search = "";
        if(CL_keyword) {
            search = CL_keyword.value;
        }
        let searchField = "";
        if(CL_keyword_type) {
            searchField = CL_keyword_type.value;
        }

        const CL_update = document.getElementById("CL_update") as HTMLSelectElement;

        const data = {
            brand_id: brand.id ? brand.id : null,

            // articleCategory: articleCategoryId,
            articleType: articleTypeId,

            search: search,
            searchField: searchField,

            hintYN: CL_hint && CL_hint.value !== "" ? CL_hint.value : undefined,
            sbsSolutionYN: CL_answer && CL_answer.value !== "" ? CL_answer.value : undefined,
            sbsChattingYN: CL_question && CL_question.value !== "" ? CL_question.value : undefined,
            articleUpdateFlag: CL_update && CL_update.value !== "" ? CL_update.value === "Y" ? true : false  : undefined,

            learning: CL_learning && CL_learning.value !== "" ? CL_learning.value : undefined,
            conversion: CL_conversion && CL_conversion.value !== "" ? CL_conversion.value : undefined,

            reviewStatus: CL_review && CL_review.value !== "" ? CL_review.value : undefined,

            curriBook: CL_curriBook_val ? CL_curriBook_val.id : undefined,
            curriUnit1: CL_html_curriUnit1_val ? CL_html_curriUnit1_val.id : undefined,
            curriUnit2: CL_html_curriUnit2_val ? CL_html_curriUnit2_val.id : undefined,
            curriUnit3: CL_html_curriUnit3_val ? CL_html_curriUnit3_val.id : undefined,
            curriUnit4: CL_html_curriUnit4_val ? CL_html_curriUnit4_val.id : undefined,
            curriUnit5: CL_html_curriUnit5_val ? CL_html_curriUnit5_val.id : undefined,

            orderby_col: "id", 
            orderby_dir: "desc",
            limit_page: 1,
            limit_pageItemCnt: localStorage.getItem("AITutor_numOfPage") ? Number(localStorage.getItem("AITutor_numOfPage")) : 10,
        };

        logger.log("onSearch", data);

        setSearchData(data);
        _onSearch(data);
    }

    function onPrevPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onPrevPage", _listOfPage);
        if(_listOfPage.length > 0) {
            const _currPage = _listOfPage[0] - 1;
            if(_currPage > 0) {
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }    
        }
    }

    function onNextPage(): void {
        const _listOfPage = listOfPage as number[];
        logger.log("onNextPage", _listOfPage);
        if(_listOfPage.length > 0) {            
            if(_listOfPage.length === numOfViewPage) {
                const _currPage = _listOfPage[_listOfPage.length - 1] + 1;
                setCurrPage(_currPage);
    
                if(list && list.length > 0) {
                    // setCurrPage(_currPage);    
                    onChangePage(_currPage);
                    // logger.log("onPrevPage", _currPage, _listOfPage);
                }
            }
        }
    }

    function onChangeNumOf1Page(e: ChangeEvent<HTMLSelectElement>): void {
        const numOfPage = e.target.value;
        setCurrPage(1);
        localStorage.setItem("AITutor_numOfPage", numOfPage);
        const _searchData = searchData;
        _searchData.limit_page = 1;
        _searchData.limit_pageItemCnt = numOfPage;
        _onSearch(_searchData);            
    }

    function onChangePage(page: number): void {
        setCurrPage(page);
        const _searchData = searchData;
        _searchData.limit_page = page;
        _onSearch(_searchData);
    }

    async function getDescendantsList(code: string) {

        props.showLoading(true);

        if(code === "0") {
            setBookDescendantsList(null);
            setCurriUnit1(null);
            setCurriDepth(0);
        } else {
            const list = await util.getDescendantsList(code);

            const curriBook = list.find((item) => item.code === code);
            if(!curriBook) {
                return;
            }
            setBookDescendantsList(list);
    
            const _filtered_curriUnit1 = list.filter((item) => item.name === "curriUnit1");
            setCurriUnit1(_filtered_curriUnit1);
    
            let max_depth = 0;
            if(curriBook.max_depth) {
                max_depth = curriBook.max_depth;
            } else {
                list.map((item) => {
                    if(max_depth < item.depth) {
                        max_depth = item.depth;
                    }
                });
                max_depth = max_depth - 1;
            }
            setCurriDepth(max_depth);        

            logger.log("getDescendantsList", list, curriBook, max_depth);
        }

        props.showLoading(false);
    }

    function onChangeCurriBook(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeCurriBook", e.target.value);
        // getChildrenList(e.target.value, "CurriBook");
        getDescendantsList(e.target.value);

        CL_html_curriUnit1.selectedIndex = 0;
        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit2([]);
        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit1(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit1", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit1");
        const _filtered_curriUnit2 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit2");
        const result = _filtered_curriUnit2.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit2(result);

        CL_html_curriUnit2.selectedIndex = 0;
        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit3([]);
        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit2(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit2", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit2");
        const _filtered_curriUnit3 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit3");
        const result = _filtered_curriUnit3.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit3(result);

        CL_html_curriUnit3.selectedIndex = 0;
        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit4([]);
        setCurriUnit5([]);
    }

    function onChangeUnit3(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit3", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit3");
        const _filtered_curriUnit4 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit4");
        const result = _filtered_curriUnit4.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit4(result);

        CL_html_curriUnit4.selectedIndex = 0;
        CL_html_curriUnit5.selectedIndex = 0;

        setCurriUnit5([]);
    }

    function onChangeUnit4(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit4", e.target.value);
        // getChildrenList(e.target.value, "CurriUnit4");
        const _filtered_curriUnit5 = (bookDescendantsList as IMeta[]).filter((item) => item.name === "curriUnit5");
        const result = _filtered_curriUnit5.filter((item) => item.parent_id === Number(e.target.value))
        setCurriUnit5(result);

        CL_html_curriUnit5.selectedIndex = 0;
    }

    function onChangeUnit5(e: ChangeEvent<HTMLSelectElement>): void {
        logger.log("onChangeUnit5", e.target.value);
    }

    // function onChangeCurriContents(newArticleCategory: string) {

    //     setCurrArticleCategory(newArticleCategory);

    //     let articleCategoryId = undefined;
    //     if(curriInfo && curriInfo.articleCategory) {
    //         const articleCategory = curriInfo.articleCategory as IMeta[];
    //         const _articleCategory = articleCategory.find((article, _) => article.val === newArticleCategory);
    //         if(_articleCategory) {
    //             articleCategoryId = _articleCategory.id;
    //         }
    //     }

    //     const _searchData = searchData;
    //     _searchData.articleCategory = articleCategoryId;

    //     logger.log("onChangeCurriContents", newArticleCategory, articleCategoryId, _searchData);

    //     setSearchData(_searchData);
    //     _onSearch(_searchData);
    // }

    async function onChangePublicOpen(item: IArticle) {
        const CL_is_publicOpen = document.getElementById("CL_is_publicOpen") as HTMLInputElement;
        if(CL_is_publicOpen) {
            logger.log("CL_is_publicOpen.checked", item, CL_is_publicOpen.checked);

            const data = {
                saveType: "update",
                article: {
                    id: item.id,
                    is_publicOpen: !item.is_publicOpen,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }
    }

    function onReview(item: IArticle) {
        if(item && item.review === 0) {
            setModalShow(true);
            setSelectedItem(item);
        }
    }

    async function onReviewOk() {
        if(selectedItem && selectedItem.review === 0) {    
            const data = {
                saveType: "update",
                article: {
                    id: selectedItem.id,
                    review: 1,
                    loginUserId : util.getUserInfo("uid"),
                },
            };

            logger.log(data);

            await util.updateArticle(data);
            _onSearch(searchData);
        }

        onReviewClose();
    }

    function onReviewClose() {
        setModalShow(false);
        setSelectedItem(null);
    }

    async function onPreView(item: IArticle) {
        // alert("미리보기 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        // const sets = await util.getSetsByArticleId(item.id);
        // logger.log("onPreView", sets);
        // if(sets && sets.length > 0) {
        //     const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
        //     +"&article_id="+item.id+"&token="+util.getUserInfo("token");
        //     window.open(url, "_blank", "noopener, noreferrer");    
        // }

        if(item) {
            const url = EDITOR_HOST+"/editor/viewer/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&article_id="+item.id+"&token="+util.getUserInfo("token");
            window.open(url, "_blank", "noopener, noreferrer");        
        }

    }

    async function onEditPage(item: IArticle | null) {
        // alert("편집 작업중...");
        const brand = util.getCurrentBrand();
        if(!brand || !brand.id) {
            return;
        }
 
        let url = "";
        if(item) {
            // const sets = await util.getSetsByArticleId(item.id);
            // if(sets && sets.length > 0) {
            //     url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            //     +"&article_id="+item.id+"&token="+util.getUserInfo("token");
            // }

            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&article_id="+item.id+"&token="+util.getUserInfo("token");

        } else {
            url = EDITOR_HOST+"/editor/index.html?brand_id="+brand.id+"&user_id="+util.getUserInfo("id")+"&user_uid="+util.getUserInfo("uid")
            +"&create=y"+"&token="+util.getUserInfo("token");
        }
        window.open(url, "_blank", "noopener, noreferrer");
    }

    // function getDataForLLM(part: {part: string, data: string}[]) {
    //     const directive = part.find(p => p.part === 'directive')?.data.replaceAll('&nbsp;', ' ');
    //     const passage = part.find(p => p.part === 'passage')?.data.replaceAll('&nbsp;', ' ');
    //     const example = part.find(p => p.part === 'example')?.data.replaceAll('&nbsp;', ' ');
    //     const inputArea = part.find(p => p.part === 'input-area')?.data.replaceAll('&nbsp;', ' ');
    
    //     const problem = (directive ? directive + '\n' : '') +
    //                                     (passage ? passage + '\n' : '') +
    //                                     (example ? example + '\n' : '') +
    //                                     (inputArea ? inputArea + '\n' : '');
    //     const solution = part.find(p => p.part === 'explanation')?.data.replaceAll('&nbsp;', ' ');
        
    //     return {problem, solution};
    // }

    function getDataForLLM(part: {part: string, data: string}[]) {
        const directive = part.find(p => p.part === 'directive')?.data.replaceAll('&nbsp;', ' ');
        const passage = part.find(p => p.part === 'passage')?.data.replaceAll('&nbsp;', ' ');
        const example = part.find(p => p.part === 'example')?.data.replaceAll('&nbsp;', ' ');
        const inputArea = part.find(p => p.part === 'input-area')?.data.replaceAll('&nbsp;', ' ');
    
        const problem = (directive ? directive + '\n' : '') +
                                        (passage ? passage + '\n' : '') +
                                        (example ? example + '\n' : '') +
                                        (inputArea ? inputArea + '\n' : '');
        const solution = part.find(p => p.part === 'explanation')?.data.replaceAll('&nbsp;', ' ');
        
        return {problem, solution};
    }

    async function onBulkUpdateHintAll() {
        for (let i = 0; i < 500; i++) {
            logger.log("onBulkUpdateHintAll", i);
            await onBulkUpdateHint(true);            
        }

        props.showModal("", "모든 콘텐츠 힌트 생성완료!!!");
    }

    async function onBulkUpdateHint(isForAll: boolean = false) {

        logger.log("onBulkUpdateHint");

        props.showLoading(true);

        const articles : {"id": number}[] = [];

        if(isForAll) {

            const _searchData = JSON.parse(JSON.stringify(searchData));
            // _searchData.articleCategory = undefined;
            // _searchData.detail = undefined;
            _searchData.limit_pageItemCnt = 200;
            _searchData.orderby_dir = "asc";
            _searchData.hintYN = "N";

            // props.showLoading(true);
            const _list = await util.getAiTutorArticleList(_searchData);
            // props.showLoading(false);

            logger.log("isForAll", _list);

            for (let i = 0; i < _list.length; i++) {
                const _data = {"id": _list[i].id};
                if(_list[i].hintYN === "N") {
                    articles.push(_data);
                }
            }    

        } else {
            const list_ck = document.getElementsByName("list_ck");
            const _articles = list as IArticle[];
            for (let i = 0; i < list_ck.length; i++) {
                const element = list_ck[i] as HTMLInputElement;
                if(element.checked) {
                    const _data = {"id": _articles[i].id};
                    articles.push(_data);
                }
            }    
        }

        if(articles.length === 0) {
            return;
        }

        if(isForAll) {
            // props.showModal("", "모든 콘텐츠 힌트 생성중(" + articles.length + ")");
            // return;
        }

        const totalCnt = articles.length;
        let successCnt = 0;

        const data = {"articles": articles};
        const result = await util.articlePartGroup(data);
        logger.log(data, result);

        if(result) {

            const articlePartGroup = result as IArticlePartGroup[];
            for (let i = 0; i < articlePartGroup.length; i++) {

                logger.log("current", i+1 + "/" + articlePartGroup.length, "totalCnt", totalCnt);

                const element = articlePartGroup[i];

                let input_area = element.part.input_area ? element.part.input_area + '\n' : '';
                if(input_area.length > 1024 * 100) {
                    input_area = "";
                }

                const problem = (element.part.directive ? element.part.directive + '\n' : '') +
                (element.part.passage ? element.part.passage + '\n' : '') +
                (element.part.example ? element.part.example + '\n' : '') +
                (input_area);

                logger.log("element.part.input_area", element.part.input_area);

                const solution = element.part.explanation ? element.part.explanation.replaceAll('&nbsp;', ' ') : "";

                // logger.log(element, "problem", problem, "solution", solution);

                const data = {
                    style: 'casual',
                    problem: problem,
                    solution: solution,
                    text_format: "html",
                };

                const defHeaders_quanda = {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, PUT',
                    'Access-Control-Max-Age': '1800',	
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'Accept': 'application/json',
                    // 'Origin': document.location.origin,
                    'Content-Type': 'application/json',
                    'X-Qanda-AIDT-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lciI6InZpc2FuZyJ9.Ke3BEy4JlPS87yth1X8nvTyFO0QZLirEEHNSPwQ5gvY',
                };
                
                const HINT_API = {
                    prod: 'https://aidt.qanda.ai',
                    // dvlp: 'https://aidt.qanda.ai',
                    dvlp: 'https://aidt.qanda-ai.dev'
                };
                       
                // let hint : {"article_id": string, "hint": string}[] = [];

                await axios.post(HINT_API.prod+'/metadata/v1/generate-problem-hint', data, { headers: defHeaders_quanda
                }).then(async (response) => {
                    logger.log(response);
                    if(response.data && response.data.hint && response.data.hint.length > 10) {
                        successCnt++;

                        const hint = {"article_id": String(element.id), "hint": response.data.hint};
                        const result = await util.aiTutorContentsForSave(hint);
                        // logger.log("aiTutorContentsForSave", hint, result);
                    }
                }).catch((err) => {
                    logger.log(err);
                });          
            }            
        }

        if(!isForAll) {
            if(totalCnt === successCnt) {
                props.showModal("", "힌트 생성이 완료되었습니다.");
            } else if(successCnt === 0) {
                props.showModal("", "힌트 생성에 실패하였습니다.");
            } else {
                props.showModal("", totalCnt-successCnt + "개의 문항에 힌트가 생성되지 않았습니다. 다시 확인해 주세요.");
            }    
        
            props.showLoading(false);

            _onSearch(searchData);
        }

        props.showLoading(false);

    }

    async function onBulkUpdateQnA() {

        logger.log("onBulkUpdateQnA");

        props.showLoading(true);

        const _article_ids : string[] = [];
        const _articles = list as IArticle[];
        const list_ck = document.getElementsByName("list_ck");
        for (let i = 0; i < list_ck.length; i++) {
            const element = list_ck[i] as HTMLInputElement;
            if(element.checked) {
                _article_ids.push(String(_articles[i].id));
            }
        }

        const result1 = await onUpdate_Math_data_gen(_article_ids);
        logger.log("result onUpdate_Math_data_gen", result1);

        const articles : {"article_id": number, "sbsSolution": string, "sbsChatting": string}[] = [];
        for (let i = 0; i < _article_ids.length; i++) {
            const _id = Number(_article_ids[i]);

            const solution = await onUpdate_Math_data_get(Number(_id), "solution");
            const chatting = await onUpdate_Math_data_get(Number(_id), "chatting");
            
            if(solution && solution.result_code === 0 && chatting && chatting.result_code === 0) {
                const _data = {
                    "article_id": Number(_id),
                    "sbsSolution": JSON.stringify(solution.result_value),
                    "sbsChatting": JSON.stringify(chatting.result_value),
                };
                articles.push(_data);        
            }
        }

        if(articles.length === 0) {
            props.showLoading(false);
            return;
        }

        const data = {"articles": articles};
        // logger.log(data);
        const result2 = await util.updateTutorusColumn(data);
        logger.log(data, result2);

        props.showLoading(false);

        _onSearch(searchData);
    }

    async function onUpdate_Math_data_get(id: number, type: string) {

        // const url = "https://tutorus.aidtclass.com/visang/math-check-get/api";
        const url = "https://tutorus.aidtclass.com/api/check/get";

        const data = 
        {
            "request_id": type+"_"+id,
            "id": id, 
            "type": type, // solution, chatting 사용 
        }        

        let result : any = null;
        await axios.post(url, data, { headers: {}
        }).then(async (response) => {
            logger.log(response);
            result = response.data;
        }).catch((err) => {
            logger.log(err);
        });          

        return result;    
    }

    async function onUpdate_Math_data_gen(ids: string[]) {
        
        const url = "https://visang.tutorusresearch.com/visang/math-data-gen/api";

        const openai_api_key = [
            "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",
            "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",
            "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",
            "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",
            "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"
        ];

        const dateTime = Date.now();
        const timestamp = Math.floor(dateTime / 1000);

        const data = 
        {
            "request_id": "problem_"+timestamp,
            "article_id": ids,
            "type": "problem",
            "image_domain":"https://t-cms.vsaidt.com",
            "data_domain":"https://t-cmsapi.vsaidt.com",
            "openai_api_key":openai_api_key[0],
        }

        // logger.log("onUpdate_Math_data_gen", data);

        let result : any = null;
        await axios.post(url, data, { headers: {}
        }).then(async (response) => {
            logger.log(response);
            result = response.data;
        }).catch((err) => {
            logger.log(err);
        });          

        return result;
    }

    async function getTutorus_math_check_ids(isAll: boolean) {

        props.showLoading(true);

        let data = {};
        if(isAll) {
            data = {"article_id": -1};
            const result = await util.updateTutorusColumn(data);
            logger.log(data, result);
        } else {
            const _article_ids : {"article_id": Number}[] = [];
            const _articles = list as IArticle[];
            const list_ck = document.getElementsByName("list_ck");
            for (let i = 0; i < list_ck.length; i++) {
                const element = list_ck[i] as HTMLInputElement;
                if(element.checked) {
                    if(_articles[i].id) {
                        _article_ids.push({"article_id": Number(_articles[i].id)});
                    }
                }
            }
            data = {"articles":_article_ids};
            if(_article_ids.length > 0) {
                const result = await util.updateTutorusColumn(data);
                logger.log(data, result);        
            }
        }
        // logger.log(data);

        props.showLoading(false);
    }

    // const isAdmin = util.getHasAuthMenu("");
    const isAdmin = true;

    return (
        <div>
            <div className="top-title">
                <h3>튜터 콘텐츠 목록</h3>
                <button type="button" className="btn-sky" onClick={() => getTutorus_math_check_ids(true)} disabled={!isAdmin}>튜터러스 리스트 전체 가져오기</button>
            </div>
                
            <div className=""  id="subject" role="tabpanel" aria-labelledby="subject-tab"> 
                
                {/* <!-- 검색영역 --> */}
                <div className="px-8 py-8 border border-gray-300 rounded-xl">
                    <div>
                        <fieldset>
                            <label htmlFor="curriBook">교과</label>
                            <select id="CL_curriBook" name="CL_curriBook" onChange={(e) => onChangeCurriBook(e)}>
                                <option value="0">교과</option>              
                                {curriInfo && curriInfo.curriBook && (curriInfo.curriBook as IMeta[]).map((item, idx) => {
                                return(
                                    <option key={idx} value={item.code}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="curriUnit1" className="ml-8">교과과정</label>
                            <select className="mr-2" id="CL_curriUnit1" name="curriUnit1" onChange={(e) => onChangeUnit1(e)} style={{display:curriDepth > 0 ? "" : "none"}}>
                                <option value="0">1Depth</option>              
                                {curriUnit1 && (curriUnit1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit2" name="curriUnit2" onChange={(e) => onChangeUnit2(e)} style={{display:curriDepth > 1 ? "" : "none"}}>
                                <option value="0">2Depth</option>              
                                {curriUnit2 && (curriUnit2 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit3" name="curriUnit3" onChange={(e) => onChangeUnit3(e)} style={{display:curriDepth > 2 ? "" : "none"}}>
                                <option value="0">3Depth</option>              
                                {curriUnit3 && (curriUnit3 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select className="mr-2" id="CL_curriUnit4" name="curriUnit4" onChange={(e) => onChangeUnit4(e)} style={{display:curriDepth > 3 ? "" : "none"}}>
                                <option value="0">4Depth</option>              
                                {curriUnit4 && (curriUnit4 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                            <select id="CL_curriUnit5" name="curriUnit5" onChange={(e) => onChangeUnit5(e)} style={{display:curriDepth > 4 ? "" : "none"}}>
                                <option value="0">5Depth</option>              
                                {curriUnit5 && (curriUnit5 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>

                    </div>
                    <div className="mt-3">
                        <fieldset>
                            <label htmlFor="search-3">콘텐츠</label>
                            <select id="CL_contentsType" name="search-3">
                                <option value={""}>전체</option>
                                <option value={"개념"}>개념</option>
                                <option value={"활동"}>활동</option>
                                <option value={"문항"}>문항</option>
                            </select>
                        </fieldset>

                        {props.brand && props.brand.code === "VAMT01" && <>
                        <fieldset>
                            <label htmlFor="search-4" className="ml-8">힌트</label>
                            <select id="CL_hint" name="search-4">
                                <option value={""}>전체</option>
                                <option value={"Y"}>Y</option>
                                <option value={"N"}>N</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">단계별 풀이</label>
                            <select id="CL_answer" name="search-5">
                                <option value={""}>전체</option>
                                <option value={"Y"}>Y</option>
                                <option value={"N"}>N</option>
                            </select>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">단계별 질문</label>
                            <select id="CL_question" name="search-5">
                                <option value={""}>전체</option>
                                <option value={"Y"}>Y</option>
                                <option value={"N"}>N</option>
                            </select>
                        </fieldset>
                        </>}

                        {props.brand && props.brand.code === "VAET01" && <>
                        <fieldset>
                            <label htmlFor="search-5" className="ml-8">학습역량</label>
                            <select id="CL_learning" name="search-5">
                                <option value={""}>전체</option>
                                {evaluationArea1 && (evaluationArea1 as IMeta[]).map((item, idx) => {
                                return(
                                <option key={idx} value={item.id}>{item.val}</option>              
                                )})}
                            </select>
                        </fieldset>
                        {/* <fieldset>
                            <label htmlFor="search-5" className="ml-8">대화형 문장 변환​</label>
                            <select id="CL_conversion" name="search-5">
                                <option value={""}>전체</option>
                            </select>
                        </fieldset> */}
                        </>}

                        <fieldset style={{display: "none"}}>
                            <label htmlFor="search-5" className="ml-8">검수 여부</label>
                            <select id="CL_review" name="search-5">
                                <option value={""}>전체</option>
                                <option value={"2"}>완료</option>
                                <option value={"1"}>이상</option>
                                <option value={"0"}>보류</option>
                            </select>
                        </fieldset>

                        <fieldset style={{display: props.brand.code === "VAMT01" ? "" : "none"}}>
                            <label htmlFor="search-5" className="ml-8">업데이트</label>
                            <select id="CL_update" name="search-5">
                                <option value={""}>전체</option>
                                <option value={"Y"}>Y</option>
                                <option value={"N"}>N</option>
                            </select>
                        </fieldset>

                        {/* <fieldset>
                            <label htmlFor="date" className="ml-8">등록기간</label>
                        </fieldset>

                        <fieldset>
                            <DatePicker id='CL_sdate' name='CL_sdate' placeholder="시작일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickSDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset>

                        <fieldset>
                            <span className="mx-3" style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}>~</span> 
                        </fieldset>

                        <fieldset>
                            <DatePicker id='CL_edate' name='CL_edate' placeholder="종료일" onChange={(e) => onChangeCalendar(e)} value={''}/>
                        </fieldset>

                        <fieldset>
                            <button type="button" className="inline-block ml-1111 align-[-8px]" onClick={onClickEDateIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(14,165,233)" className="cal w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>                                            
                            </button> 
                        </fieldset> */}

                    </div>
                    <div className="mt-3 text-right">
                        <fieldset>
                            {/* <label htmlFor="search-6">검색</label> */}
                            <select id="CL_keyword_type" name="search-6">
                                <option value={"creator_name"}>이름</option>
                                <option value={"name"}>콘텐츠 명</option>
                                <option value={"id"}>KEY</option>
                                <option value={"hashTags"}>해시태그</option>
                            </select>
                            <input type="text" name="search" id="CL_keyword" className="w-[300px] ml-3" onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch();
                                }
                            }} />
                            <button type="button" className="h-[36px] btn-sky-s ml-3" onClick={onSearch}>
                                <svg aria-hidden="true" viewBox="0 0 20 20" className="flex-none w-5 h-5 fill-white group-hover:fill-white md:group-hover:fill-slate-400"><path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z"></path></svg>
                            </button>
                        </fieldset>
                    </div>                        
                </div>
                {/* <!-- .//검색영역 --> */}

                <div className="mt-6 mb-3 text-right">

                    {props.brand.code === "VAMT01" && <>
                    {/* {isDev && util.getUserInfo("uid") === "chaesy" &&
                    <button type="button" className="btn-sky mr-2" onClick={() => onBulkUpdateHintAll()}>모든 콘텐츠 힌트 생성</button>
                    } */}
                    <button type="button" className="btn-white-s" onClick={() => getTutorus_math_check_ids(false)} disabled={!isAdmin}>튜터러스 리스트 가져오기</button>
                    <button type="button" className="btn-white-s ml-3" onClick={() => onBulkUpdateHint()} disabled={!isAdmin}>힌트 일괄 생성</button>
                    <button type="button" className="btn-white-s ml-3" onClick={() => onBulkUpdateQnA()} disabled={!isAdmin}>단계별 일괄 생성</button>
                    </>}

                    <label htmlFor="page-num" className="ml-3">페이지당 표기 수</label>
                    <select id="pagenum" name="page-num" onChange={onChangeNumOf1Page}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </select>
                </div>

                {/* <!-- table --> */}
                {viewList &&
                <table>
                    <caption className="sr-only">교과 콘텐츠 테이블</caption>
                    <thead>
                        <tr>
                            <th style={{display:props.brand.code === "VAMT01" ? "" : "none"}} scope="col" className="pl-4 pr-3">
                                <input id="all_check" className="h-4 w-4 rounded mt-2" type="checkbox" onClick={(e) => {
                                const list_ck = document.getElementsByName("list_ck");
                                for (let i = 0; i < list_ck.length; i++) {
                                    const element = list_ck[i] as HTMLInputElement;
                                    if(!element.disabled) {
                                        element.checked = (e.target as HTMLInputElement).checked;
                                    }                                    
                                }
                            }} /></th>

                            <th scope="col" className="pl-4 pr-3">번호</th>
                            <th scope="col" className="pl-4 pr-3">Key</th>
                            <th scope="col" className="px-3">교과</th>
                            {/* <th scope="col" className="px-3">교육과정 표준체게</th> */}
                            <th scope="col" className="px-3">콘텐츠</th>
                            <th scope="col" className="px-3">콘텐츠 명</th>
                            {/* <th scope="col" className="px-3">연관 라이브러리 KEY</th> */}
                            <th scope="col" className="px-3">해시태그</th>

                            {props.brand && props.brand.code === "VAMT01" && <>
                            <th scope="col" className="px-3">힌트</th>
                            <th scope="col" className="px-3">단계별​ 풀이​</th>
                            <th scope="col" className="px-3">단계별​ 질문</th>
                            <th scope="col" className="px-3">업데이트</th>
                            </>}

                            {props.brand && props.brand.code === "VAET01" && <>
                            <th scope="col" className="px-3">평가영역​</th>
                            </>}

                            <th scope="col" className="px-3">편집</th>
                            {/* {props.brand && props.brand.code === "VAMT01" && <th scope="col" className="pl-3 pr-4">검수여부</th>} */}
                        </tr>
                    </thead>
                    <tbody>

                    {viewList && list &&
                        <tr style={{display: (list as IArticle[]).length === 0 ? "" : "none"}}>
                            <td colSpan={props.brand && props.brand.code === "VAMT01" ? 11 : 8}>{searchData ? "등록된 오브젝트가 없습니다." : "조회할 데이터를 검색해 주세요."}</td>
                        </tr>}

                    {viewList && list && (list as IArticle[]).map((item, idx) => {

                        let _unitVal = "";
                        let _curriBookVal = ""

                        if(curriInfo && curriInfo.curriBook) {
                            const curriBook = curriInfo.curriBook as IMeta[];
                            const _curriBook = curriBook.find((book, _) => book.id === item.curriBook);
                            _curriBookVal = _curriBook ? _curriBook.val : "";

                            // const curriUnit1 = curriInfo.curriUnit1 as IMeta[];
                            // const curriUnit2 = curriInfo.curriUnit2 as IMeta[];
                            // const curriUnit3 = curriInfo.curriUnit3 as IMeta[];
                            // const curriUnit4 = curriInfo.curriUnit4 as IMeta[];
                            // const curriUnit5 = curriInfo.curriUnit5 as IMeta[];

                            // const _curriUnit1 = curriUnit1.find((unit, _) => unit.id === item.curriUnit1);
                            // const _curriUnit2 = curriUnit2.find((unit, _) => unit.id === item.curriUnit2);
                            // const _curriUnit3 = curriUnit3.find((unit, _) => unit.id === item.curriUnit3);
                            // const _curriUnit4 = curriUnit4.find((unit, _) => unit.id === item.curriUnit4);
                            // const _curriUnit5 = curriUnit5.find((unit, _) => unit.id === item.curriUnit5);

                            // const arr = [_curriUnit1?.val, _curriUnit2?.val, _curriUnit3?.val, _curriUnit4?.val, _curriUnit5?.val];
                            // arr.map((val, idx) => {
                            //     if(val) {
                            //         if(_unitVal === "") {
                            //             _unitVal = val;    
                            //         } else {
                            //             if(idx === 2) {
                            //                 _unitVal = _unitVal + " > " + "<br/>" + val;

                            //             } else {
                            //                 _unitVal = _unitVal + " > " + val;
                            //             }
                            //         }
                            //     }
                            // });
                        }

                        const totalCnt = listCount;
                        const _numOfPage = localStorage.getItem("AITutor_numOfPage") ? Number(localStorage.getItem("AITutor_numOfPage")) : 10;
                        let _num = totalCnt - ((currPage-1)*_numOfPage+idx);

                        let _articleTypeVal = "";
                        if(curriInfo && curriInfo.articleType) {
                            const articleType = curriInfo.articleType as IMeta[];
                            const _articleType = articleType.find((article, _) => article.id === item.articleType);
                            _articleTypeVal = _articleType ? _articleType.val : "-";
                        }

                        let updateDate = "-";
                        if(item.updatedate) {
                            updateDate = item.updatedate.substring(0, 10).replaceAll("-", ".");
                        }
                        
                        let hashTag = "";
                        const arrHashTag = item.hashTag as string[];
                        if(arrHashTag) {
                            arrHashTag.map((item) => {
                                if(item.trim() !== "") {
                                    hashTag = hashTag + (item[0] !== "#" ? "#" : "") + item + " ";
                                }
                            });    
                        }

                        // let libraryIds = "";
                        // const libraryMap = item.libraryMap;
                        // if(libraryMap) {
                        //     libraryMap.map((item) => {
                        //         if(libraryIds === "") {
                        //             libraryIds = String(item.id);
                        //         } else {
                        //             libraryIds = libraryIds + "," + item.id;
                        //         }
                        //     });
                        // }

                        // let hint = "";
                        // if(item.hint) {
                        //     hint = item.hint;
                        // }
                        // let directive = "";
                        // if(item.directive) {
                        //     directive = item.directive;
                        // }
                        // let passage = "";
                        // if(item.passage) {
                        //     passage = item.passage;
                        // }
                        // let example = "";
                        // if(item.example) {
                        //     example = item.example;
                        // }
                        // let model_answer = "";
                        // if(item.model_answer) {
                        //     model_answer = item.model_answer;
                        // }
                        // let explanation = "";
                        // if(item.explanation) {
                        //     explanation = item.explanation;
                        // }
                        // let input_area = "";
                        // if(item.input_area) {
                        //     input_area = item.input_area;
                        // }
                    
                        //보류(0),이상(1),완료(2)
                        let reviewStatus = "";
                        if(item.reviewStatus === 0) {
                            reviewStatus = "보류";
                        } else if(item.reviewStatus === 1) {
                            reviewStatus = "이상";
                        } else if(item.reviewStatus === 2) {
                            reviewStatus = "완료";
                        }

                        const hasAuth = util.getHasAuthMenu(item.creator ? item.creator : "");

                        return (

                            <tr key={idx}>
                                <td style={{display:props.brand.code === "VAMT01" ? "" : "none"}}>
                                    <input id={"list_ck_"+idx} name="list_ck" type="checkbox" className="h-4 w-4 rounded mt-2" onClick={(e) => {
                                        const list_ck = document.getElementsByName("list_ck");
                                        let cntChecked = 0;
                                        for (let i = 0; i < list_ck.length; i++) {
                                            const element = list_ck[i] as HTMLInputElement;
                                            if(element.checked) {
                                                cntChecked = cntChecked + 1;
                                            }
                                        }
                                        const all_check = document.getElementById("all_check") as HTMLInputElement;
                                        if(cntChecked === list_ck.length) {                                                
                                            all_check.checked = true;                                                
                                        } else {
                                            all_check.checked = false;                                                
                                        }
                                    }}/>
                                </td>
                                <td>{_num}</td>
                                <td>{item.id}</td>
                                <td>{_curriBookVal}</td>
                                {/* <td dangerouslySetInnerHTML={{ __html: _unitVal }}></td> */}
                                <td>{_articleTypeVal}</td>
                                <td>{item.name}</td>
                                {/* <td>{libraryIds}</td> */}
                                <td>{hashTag}</td>

                                {props.brand && props.brand.code === "VAMT01" && <>
                                <td>{item.hintYN}</td>
                                <td>{item.sbsSolutionYN}</td> 
                                <td>{item.sbsChattingYN}</td>
                                <td>{item.articleUpdateFlag ? "Y" : "N"}</td>
                                </>}

                                {props.brand && props.brand.code === "VAET01" && <>
                                <td>{item.meta_code ? item.meta_code : ""}</td>
                                </>}

                                <td><button type="button" className="btn-white-s" disabled={item.review === 1 || !hasAuth} onClick={() => onEditPage(item)}>편집</button></td>
                                {/* {props.brand && props.brand.code === "VAMT01" && <td>{reviewStatus}</td>} */}

                                {/* <td>{item.regdate.substring(0, 10).replaceAll("-", ".")}</td>
                                <td>{updateDate}</td>
                                <td><button type="button" className="btn-white-s" onClick={() => onPreView(item)}>미리보기</button></td>
                                <td><button type="button" className="btn-white-s" disabled={item.review === 1} onClick={() => onEditPage(item)}>편집</button></td>
                                <td>
                                    <label className="inp-toggle">
                                        <input type="checkbox" id="CL_is_publicOpen" value="" className="peer" defaultChecked={item.is_publicOpen} onChange={() => onChangePublicOpen(item)}/>
                                        <div className="peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-sky-500"></div>
                                    </label>
                                </td>
                                <td>
                                    //<!-- 완료 !text-sky-500 !bg-sky-50 추가-->
                                    <button type="button" className={item.review === 0 ? "btn-white-s" : "btn-white-s !text-sky-500 !bg-sky-50"} onClick={() => onReview(item)}>{item.review === 0 ? "통과" : "완료"}</button>
                                </td> */}
                            </tr>
                        )
                        })}

                    </tbody>
                </table>}
                {/* <!-- .//table -->	 */}

                <div className="relative mt-5">

                    {/* <!-- pagenation --> */}
                    <div className="text-center">
                        <nav className="pagenation" aria-label="Pagination">

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="prev rounded-l-md" onClick={onPrevPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                </svg>
                            </div>}

                            {listOfPage && (listOfPage as number[]).map((item, idx) => {
                            return (
                                currPage === listOfPage[0]+idx ? <a key={idx} href="#" aria-current="page">{item}</a> : <a key={idx} href="#" onClick={() => onChangePage(item)}>{item}</a>
                            )})}

                            {listOfPage && (listOfPage as number[]).length > 0 &&
                            <div className="next rounded-r-md" onClick={onNextPage} style={{cursor:"pointer"}}>
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                </svg>
                            </div>}
                            
                        </nav>
                    </div>
                    {/* <!-- .// pagenation --> */}
                </div>
            </div>

            <div className="popup-wrap" aria-labelledby="modal-title" role="dialog" aria-modal="true" style={{display : modalShow ? "" : "none"}}>
                <div className="dim"></div>
                <div className="popup-contents">
                    <div className="popup-contents-inner">
                        <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">&#8203;</span>
                        <div className="popup-box max-w-xl min-w-[576px]">
                            {/* <!-- 팝업 내용 --> */}
                            <div className="inner">
                                <button type="button" className="btn-close" onClick={onReviewClose}>
                                    <span className="sr-only">Close</span>
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                                <h2 className="pop-title">심사 확인</h2>
                                <div className="px-5">
                                    <div className="py-8 text-center">
                                        <p>심사 완료된 콘텐츠로 변경 하시겠습니까?</p>
                                    </div>
                                </div>
                                <div className="my-5 text-center">
                                    <button type="button" className="btn-sky" onClick={onReviewOk}>확인</button>

                                </div>
                            </div>
                            {/* <!-- .//팝업 내용 --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );  
}
export default AITutorContentsList;
